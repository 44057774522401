<!-- 政策匹配-->
<template>
  <div style="background-color: #f5f6fa">
    <div class="def-container" style="padding-top: 2rem">
      <el-row>
        <el-col :span="12" style="font-size: 32px; color: #0a44a5">
          政策匹配
        </el-col>
      </el-row>
    </div>
    <div
      class="def-container find-list-container"
      style="background-color: #fff"
    >
      <div class="findList" ref="findList">
        <!--筛选-->
        <div class="find-tabs">
          <el-row class="checkboxtype1" style="width: 100%; padding-top: 2rem">
            <!-- <FilterGroup @change="changeSearch"> -->
            <FilterCheckBox
              v-model="searchInfo.data.hyle"
              label="行业类别"
              :list="getDictList('sys_category')"
              valueKey="key"
              :needAll="false"
            ></FilterCheckBox>
          </el-row>
          <el-row class="checkboxtype1" style="width: 100%; margin-top: 2rem">
            <FilterCheckBox
              v-model="searchInfo.data.zcfl"
              label="政策分类"
              :list="getDictList('sq_policy_xmlb')"
              valueKey="key"
              :needAll="false"
            >
            </FilterCheckBox>
          </el-row>
          <el-row style="width: 100%; margin-top: 2rem">
            <el-col :span="2" style="line-height: 32px;color: #74869a;">注册地</el-col>
            <el-col :span="10">
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 32%; margin-left: 1%"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 32%; margin-left: 1%"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                </el-option>
              </el-select>
              <el-select
                v-model="value"
                placeholder="请选择"
                style="width: 32%; margin-left: 1%"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4" style="line-height: 32px;color: #74869a;" align="center"
              >成立时间</el-col
            >
            <el-col :span="8">
              <el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row style="width: 100%; margin-top: 2rem">
            <el-col :span="2" style="line-height: 32px;color: #74869a;">是否高新</el-col>
            <el-col :span="5">
              <el-checkbox v-model="checked1">国家高新</el-checkbox>
              <el-input style="width: 50px; margin-left: 0.5rem" />
              <span>年</span>
            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="checked2">市高新</el-checkbox>
              <el-input style="width: 50px; margin-left: 0.5rem" />
              <span>年</span>
            </el-col>
          </el-row>
          <el-row style="width: 100%; margin-top: 2rem">
            <el-col :span="2" style="line-height: 32px;color: #74869a;">企业资质</el-col>
            <el-col
              :span="22"
              style="background-color: #f6f8fb; padding-left: 1rem"
            >
              <el-row class="insideTitle"> 创新载体 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="行业类别"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                ></FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工程中心"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工程实验室"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="重点实验室"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工业设计中心"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 科技奖励 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工业设计奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="科学设计奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="专利奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 科技奖励 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工业设计奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="科学设计奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="专利奖"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 企业荣誉 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="知识产权优势企业"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="工业百强"
                  :list="areaList"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="其他"
                  :list="getDictList('enterprise_honor')"
                  valueKey="key"
                  :needAll="false"
                >
                </FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 贯标认证 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="贯标认证"
                  :list="getDictList('standard_certification')"
                  valueKey="key"
                  :needAll="false"
                ></FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 资本运作 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="资本运作"
                  :list="getDictList('capital_operation')"
                  valueKey="key"
                  :needAll="false"
                ></FilterCheckBox>
              </el-row>
              <el-row class="insideTitle"> 行业资质 </el-row>
              <el-row style="width: 100%">
                <FilterCheckBox
                  v-model="searchInfo.data.hyle"
                  label="行业资质"
                  :list="getDictList('policy_industry')"
                  valueKey="key"
                  :needAll="false"
                ></FilterCheckBox>
              </el-row>
            </el-col>
          </el-row>
          <!-- </FilterGroup> -->
        </div>
      </div>
      <div style="text-align: right; margin-top: 2rem;padding-bottom:2rem">
        <el-button
          style="color: #fff; background-color: #0d55cf; width: 180px"
          >立即匹配</el-button
        >
      </div>
      <!--登录提示弹窗-->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>需要登录才能收藏，是否登录？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="gotoLogin">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  // FilterGroup,
  // FilterRadio,
  FilterCheckBox,
  // FilterItem,
} from "@/views/policyLibrary/components/filter/index.js";

export default {
  components: {
    // FilterGroup,
    // FilterRadio,
    FilterCheckBox,
    // FilterItem,
  },
  data() {
    return {
      dialogVisible: false, //登录提示弹窗
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 5,
        total: 0,
      },
      areaList: [
        { id: 1, label: "区(县/镇)级", value: 1 },
        { id: 2, label: "市级", value: 2 },
        { id: 3, label: "省级", value: 3 },
        { id: 4, label: "国家级", value: 4 },
      ],
      cities: [
        {
          value: "Beijing",
          label: "北京",
        },
        {
          value: "Shanghai",
          label: "上海",
        },
        {
          value: "Nanjing",
          label: "南京",
        },
        {
          value: "Chengdu",
          label: "成都",
        },
        {
          value: "Shenzhen",
          label: "深圳",
        },
        {
          value: "Guangzhou",
          label: "广州",
        },
      ],
      value: "",
    };
  },
   computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
  },
  methods: {
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "支持方式":
          if (value !== "1") {
            this.$set(this.searchInfo.data, "temp_amount", "");
            this.searchInfo.data.zzxeleft = "";
            this.searchInfo.data.zzxeright = "";
          }
          break;
        case "资金限额":
          if (value == "4") return;
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getListData(page, size) {
      /**
       * 20210520
       * 查询区分了 传统列表/数字化列表
       * 为了可控，用switch分开处理逻辑
       *
       * 20210615
       * 资助标准列表，如果按资助标准的维度来查询，会带来序号的问题
       * 逻辑改为，先调传统政策项目列表，再传批量id查询资助标准
       */
      this.pageInfo.page = page ?? this.pageInfo.page;
      this.pageInfo.size = size ?? this.pageInfo.size;

      let url =
        "/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list";
      let params = {
        itemtype: "2",
        release: "1",
        ...this.searchInfo.oldParams,
        ["PAGE_CUR"]: this.pageInfo.page.toString(),
        ["PAGE_ROWS"]: this.pageInfo.size.toString(),
      };
      // 特殊处理一下 奖励金额
      switch (params.temp_amount) {
        case "0":
          params.zzxeleft = 1000;
          break;
        case "1":
          params["zzxe_500_1000"] = "1";
          break;
        case "2":
          params["zzxe_100_500"] = "1";
          break;
        case "3":
          params.zzxeright = 100;
          break;
        case "4":
          params.zzxeleft = Number(params.zzxeleft);
          params.zzxeright = Number(params.zzxeright);
          break;
        default:
          break;
      }
      Reflect.deleteProperty(params, "temp_amount");

      this.dataLoading = true;
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getListData:", res);
          this.dataLoading = false;

          this.pageInfo.total = res.pageInfo.pageCount || 0;
          switch (this.searchInfo.type) {
            case "0":
              this.showList = res.data || [];
              break;
            case "1":
              if (res.data && !!res.data.length) {
                let zzbzUrl =
                  "/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list";
                let zzbzParams = JSON.parse(JSON.stringify(params));
                zzbzParams.sxids = res.data.map((cv) => cv.id).join(",");
                Reflect.deleteProperty(zzbzParams, "PAGE_CUR");
                Reflect.deleteProperty(zzbzParams, "PAGE_ROWS");

                this.$httpApi.post(zzbzUrl, zzbzParams).then((resp) => {
                  console.log("zzbzData:", resp);
                  this.showList = [];
                  // 处理下序号的问题
                  // 按照政策项目，依次推入
                  for (let i = 0; i < res.data.length; i++) {
                    let temp_zzbzList = resp.data.filter(
                      (cv) => cv.id === res.data[i].id
                    );
                    temp_zzbzList.forEach((zzbz) => {
                      zzbz.serialNumber = i;
                      this.showList.push(zzbz);
                    });
                  }

                  // 获取 企业资质
                  for (let i = 0; i < this.showList.length; i++) {
                    let qyzzUrl =
                      "/dev-api/sqzdManager/data/find/sq_policylabelname_query";
                    let qyzzParams = { labelcodes: this.showList[i].qyzz };
                    this.$httpApi
                      .post(qyzzUrl, qyzzParams)
                      .then((respo) => {
                        // console.log('getQyzz:', respo)
                        if (respo.data && !!respo.data.length) {
                          this.$set(
                            this.showList[i],
                            "qyzz_text",
                            respo.data.map((cv) => cv.name).join(", ")
                          );
                        }
                      })
                      .catch((err) => {
                        console.log("getQyzz error:", err);
                      });
                  }
                });
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log("getListData error:", err);
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_industry", //行业资质
        "capital_operation", //资本运作
        "enterprise_honor", //企业荣誉-其他
        "standard_certification", //贯标认证
      ],
    });
    this.getDeptList();
  },
  mounted() {
    this.getListData();
  },
  watch: {
    userInfo: {
      // deep: true,
      immediate: true,
      handler: function (newVal) {
        // console.log('userInfo:', newVal)
        this.getCollectList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 15px;
    }
  }

  .section-header {
    border-bottom: 4px solid #000;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 24px;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 46px;
    line-height: 46px;
  }

  .rank-section {
    padding-top: 10px;
    font-size: 13px;
  }

  .find-tabs {
    margin-top: 20px;
  }
}
.find-list-container {
  .el-input {
    input {
      background-color: #ffffff;
      border-radius: 0px !important;
    }
  }

  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 360px;
    display: flex;

    .el-button {
      border: 0px;
      border-radius: 0px;
      width: 90px;

      .el-icon-bottom {
        margin-left: 15px;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }
}
::v-deep .searchItem-amount {
  border-top: none !important;

  .filterItem-label {
    opacity: 0;
  }
  .filterItem-content {
    padding-left: 73px;

    .content-item--active {
      color: $col-theme !important;
      background: none !important;
    }
  }

  .searchItem-amount-custom {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #999999;

    .form-input {
      width: 80px;
      margin-right: 5px;
    }
  }
}
.search-params-clean {
  margin-right: 5px;
  color: $col-red;
}

.find-list-items-container {
  .item {
    margin-bottom: 10px;
    box-sizing: border-box;
  }
}
::v-deep .table-policy {
  .table-policy-header {
    th {
      color: #666666;
      background-color: #dbdada;
    }
  }
  .policy-item {
    .policyItem-hylb,
    .policyItem-qyzz,
    .policyItem-ryxxyq {
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .policyItem-zzxe {
      color: $col-red;
    }
    .policyItem-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .tj-icon {
        font-size: 20px;
        color: #999999;

        &:hover {
          color: #666666;
        }
      }
    }

    &:hover {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.pagination {
  margin: 30px auto;
  text-align: center;
}
.filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    color: $col-theme;

    .el-tag__close {
      color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
.insideTitle {
  font-size: 16px;
  color: #333;
  height: 2rem;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
::v-deep .filterItem-label {
  color: #74869a;
}
::v-deep .filterItem-content .content-item {
  background-color: #fff;
  margin-bottom: 0.5rem;
  color: #0a1f44;
}
::v-deep .filterItem-content .content-item:hover {
  color: #0a1f44;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #0a1f44;
}
::v-deep .checkboxtype1 .filter-item .filterItem-content .content-item {
  background-color: #f6f8fb;
  margin-bottom: 0.5rem;
  color: #0a1f44;
}
</style>